<template>
  <div class="legacy-container">
    <Typography variant="pc-title-48 desktop-nav-only">Drops</Typography>
    <div class="legacy-counts">
      <Count name="Artists" :value="globalData.collectionGroups || '-'" />
      <Count name="Series" :value="globalData.collections || '-'" />
      <Count name="Pieces" :value="globalData.types || '-'" />
      <Count
        name="Minted"
        :value="`${globalClaimsData.totalClaimed || '-'}`"
      />
    </div>
    <!-- sort by template -->
    <div class="series-sortby">
      <p>SORT BY:</p>
      <v-select
        outlined
        hide-details
        :value="1"
        :items="sortByOptions"
        @change="updateSort"
      />
    </div>
    <div class="series-container">
      <div v-for="(seriesItem, index) in seriesOrdered" :key="index" class="series-card">
        <div
          v-if="seriesData[seriesItem.id]"
          class="series-card__main"
          @click="seriesItem.showTypes ? hideSeriesTypes(index) : showSeriesTypes(seriesItem.id, index)"
        >
          <div class="series-card__image">
            <img :src="seriesItem.image" :alt="seriesItem.name">
          </div>
          <div class="series-card__data">
            <Typography variant="pc-title-48 mobile-title-32">{{ seriesItem.name }}</Typography>
            <Typography variant="pc-title-24 mobile-title-24">
              By
              <router-link :to="`/artists/${seriesItem.collectionGroup.id}`">
                {{ seriesItem.collectionGroup.name }}
              </router-link>
            </Typography>
            <p class="series-card__data-description">{{ seriesItem.description }}</p>
            <div class="series-card__data-items">
              <!-- items details need to iterate this section to display items -->
              <div class="series-card__data-item">
                <div class="series-card__data-label">Variants</div>
                <div class="series-card__data-value">{{ seriesData[seriesItem.id].numTypes }}</div>
              </div>
              <!-- <div class="series-card__data-item">
                <div class="series-card__data-label">MINTED</div>
                <div class="series-card__data-value">9</div>
              </div> -->
              <div class="series-card__data-item">
                <div class="series-card__data-label">Cap</div>
                <div class="series-card__data-value">{{ seriesData[seriesItem.id].capTotal }}</div>
              </div>
              <div class="series-card__data-item">
                <div class="series-card__data-label">On Market</div>
                <div class="series-card__data-value">{{ seriesData[seriesItem.id].onMarket }}</div>
              </div>
            </div>
          </div>
          <div class="series-card__actions">
            <!-- template value -->
            <span class="mobile-only cap">
              <Typography variant="pc-title-24 mobile-title-24">
                {{ seriesData[seriesItem.id].capTotal }}
              </Typography>
            </span>
            <span class="series-card__expand">
              <v-btn v-if="seriesItem.showTypes" small icon>
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-btn v-else small icon>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </span>
          </div>
        </div>
        <div v-if="seriesItem.showTypes && seriesItem.types" class="series-card__types">
          <v-data-table
            class="blkmnt"
            :headers="computedHeaders"
            :items="seriesItem.types || []"
            :mobile-breakpoint="0"
            :disable-pagination="true"
            :hide-default-footer="true"
            @click:row="viewOnMarket"
          >
            <template v-slot:[`item.name`]="{ item }">
              <div class="series-card__types-name">
                <div class="image-container">
                  <div class="mobile-only">
                    <v-progress-circular v-if="loadingUserOwnedTokens" indeterminate size="24"></v-progress-circular>
                    <div v-else-if="checkOwnership(item)" class="owned-overlay">
                      <v-icon class="owned">
                        mdi-checkbox-marked-circle-outline
                      </v-icon>
                    </div>
                  </div>
                  <img
                    :src="tokenImagePath(item.collection, formatType(item), 100, true)"
                    class="image"
                  />
                </div>
                <div>
                  <div class="name">{{ item.typeSecondary.name }}</div>
                  <div class="work">{{ item.typePrimary.id }}</div>
                </div>
              </div>
            </template>
            <template v-slot:[`item.typePrimary.id`]="{ item }">
              {{ item.typePrimary.name }}
            </template>
            <template v-slot:[`item.numMinted`]="{ item }">
              <div class="series-card__types-marketdata">
                <div>
                  <span class="minted">
                    {{ item.numMinted }}
                    <span class="mobile-only"> / </span>
                  </span>
                  <span class="mobile-only cap">{{ item.cap }}</span>
                </div>
                <div v-if="item.marketData" class="onmarket mobile-only">
                  {{ item.marketData.onMarket }} On Market
                </div>
              </div>
            </template>
            <template v-slot:[`item.marketData.onMarket`]="{ item }">
              <div class="series-card__types-onMarket">
                {{ item.marketData.onMarket }}
              </div>
            </template>
            <template v-slot:[`item.owned`]="{ item }">
              <div class="series-card__types-ownership">
                <v-progress-circular v-if="loadingUserOwnedTokens" indeterminate size="24"></v-progress-circular>
                <template v-else>
                  <v-icon v-if="checkOwnership(item)" class="owned">mdi-checkbox-marked-circle-outline</v-icon>
                  <v-icon v-else class="not-owned">mdi-checkbox-marked-circle-outline</v-icon>
                </template>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Count from '../components/about/Count.vue'
import { doc, query, getDoc, getDocs } from 'firebase/firestore'

export default {
  data () {
    return {
      userOwnedTokens: null,
      loadingUserOwnedTokens: false,
      globalData: {},
      seriesData: {},
      legacyHeaders: [
        {
          text: 'Variant',
          value: 'name',
          width: '36%'
        },
        {
          text: 'Piece',
          value: 'typePrimary.id',
          hide: 'mdAndDown',
          width: '23%'
        },
        {
          text: 'MINTED',
          value: 'numMinted',
          width: '12%'
        },
        {
          text: 'CAP',
          value: 'marketData.cap',
          hide: 'smAndDown',
          width: '12%'
        },
        {
          text: 'MARKET',
          value: 'marketData.onMarket',
          hide: 'smAndDown',
          width: '12%'
        },
        {
          text: '',
          value: 'owned',
          sortable: false,
          hide: 'smAndDown',
          width: '5%'
        }
      ],
      series: [],
      seriesOrdered: [],
      sortByOptions: [
        {
          text: 'RELEASE DATE (NEWEST FIRST)',
          value: 1
        },
        {
          text: 'RELEASE DATE (OLDEST FIRST)',
          value: 2
        }
      ]
    }
  },
  components: {
    Count
  },
  async mounted () {
    this.claimsMetadataListener()
    this.claimDataCollectionsListener()
    const global = await getDoc(doc(this.$fb.db, 'market-data', 'global'))
    const series = await getDoc(doc(this.$fb.db, 'market-data', 'series'))
    this.globalData = global.data()
    this.seriesData = series.data()
    const res = await getDocs(query(this.$fb.seriesCollection))
    res.forEach((series) => {
      this.series.push(series.data())
    })
    this.updateSort(1)
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'claimsMetadata',
      'claimDataCollections'
    ]),
    globalClaimsData () {
      return this.claimDataCollections.global || {}
    },
    computedHeaders () {
      return this.legacyHeaders.filter((h) => {
        if (
          !this.$vuetify.breakpoint[h.hide] && // hide below breakpoints
          ((h.value === 'owned' && this.userProfile) || h.value !== 'owned') // hide owned if there's no user profile
        ) {
          return true
        } else {
          return false
        }
      })
    }
  },
  methods: {
    ...mapActions([
      'claimDataCollectionsListener',
      'claimsMetadataListener',
      'getSeriesTypes'
    ]),
    updateSort (type) {
      this.seriesOrdered = this.series
      this.seriesOrdered.sort((a, b) => {
        const dateA = new Date(a.releaseDate)
        const dateB = new Date(b.releaseDate)
        switch (type) {
          case 1:
            return dateB - dateA
          case 2:
            return dateA - dateB
          default:
            return dateB - dateA
        }
      })
    },
    async showSeriesTypes (seriesId, index) {
      const types = await this.getSeriesTypes(seriesId)
      // Add numMinted (and any other extra data from elsewhere)
      types.forEach((type) => {
        type.numMinted = this.claimsMetadata[this.formatType(type)] || 0
      })
      this.$set(this.series[index], 'types', types)
      this.$set(this.series[index], 'showTypes', true)

      if (this.userProfile && !this.userOwnedTokens) {
        this.loadingUserOwnedTokens = true
        try {
          const owned = await this.fbCall('getOwnedTokens', this.userProfile.nearId)
          this.userOwnedTokens = owned.data
        } catch (err) {
          this.addNotification('Error retrieving user owned tokens.', 'error')
        }
        this.loadingUserOwnedTokens = false
      }
    },
    async hideSeriesTypes (index) {
      this.$set(this.series[index], 'showTypes', false)
    },

    checkOwnership (type) {
      let owned = false
      const typeToCheck = this.formatType(type)
      if (this.userOwnedTokens) {
        this.userOwnedTokens.forEach((token) => {
          if (this.parseTokenType(token.id) === typeToCheck) owned = true
        })
      }
      return owned
    },
    viewOnMarket (target) {
      this.$router.push(`/market/${target.collection}/${target.typePrimary.id}/${target.typeSecondary.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.legacy-container {
  .legacy-counts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 3fr;
    gap: 20px;
    margin: 20px 0;

    @media (max-width: $bp-desktop-s) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 17px 8px;

      & > *:last-child {
        grid-column: 1 / -1;
      }
    }
  }

  .series-sortby {
    width: 48%;
    margin: $space-ll 0 $space-ml auto;

    @include mobile {
      width: 100%;
      margin: $space-ll 0 $space-ml;
    }

    p {
      margin-bottom: $space-ms;
    }
  }

  .series-container {
    .series-card {
      margin-bottom: $space-ms;

      &__main {
        cursor: pointer;
        position: relative;
        display: flex;
        background-color: $blk-black;
        padding: $space-ll;
      }

      &__image {
        width: 50%;
        padding-right: $space-ms;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top;
        }
      }

      &__data {
        width: 50%;
        padding-left: $space-ms;

        &-description {
          margin: $space-ml 0 0;
          font-size: $font-size-s;
          font-family: $font-title;
          white-space: pre-wrap;
          line-height: 1.6em;

          @include mobile {
            font-size: $font-size-s;
          }
        }

        &-items {
          padding-top: $space-ms;
          padding-bottom: $space-l;
        }

        &-item {
          display: flex;
          justify-content: space-between;
          margin-top: $space-s;
        }

        &-label {
          color: $blk-grey-4;
          font-size: $font-size-s;
          width: 50%;
          padding: $space-xs 0;
        }

        &-value {
          color: $blk-white;
          font-size: $font-size-s;
          width: 50%;
          border-bottom: 1px solid $blk-grey-4;
          padding: $space-xs 0;
          text-align: right;
        }
      }

      &__actions {
        @include mobile {
          display: flex;
          justify-content: space-between;
        }
      }

      &__expand {
        position: absolute;
        top: 20px;
        right: 25px;
        cursor: pointer;

        @include mobile {
          position: unset;
        }
      }

      &__types {
        &-name {
          display: flex;
          align-items: center;

          .image-container {
            position: relative;
            margin-right: 30px;
            width: 60px;
            height: 60px;

            @include media($bp-desktop-s) {
              margin-right: 16px;
            }

            @include mobile {
              margin-right: 10px;
              width: 50px;
              height: 50px;
            }

            .owned-overlay {
              @include fill-parent;
              background-color: transparentize(black, 0.5);
            }

            .v-icon {
              @include center-in-parent;
            }
          }

          .image {
            width: 60px;
            height: 60px;

            @include mobile {
              width: 50px;
              height: 50px;
            }
          }

          .name {
            color: $blk-white;
            font-size: 16px;
            font-weight: 700;
            font-family: $font-title;

            @include media($bp-desktop-s) {
              font-size: $font-size-m;
            }

            @include mobile {
              font-size: $font-size-s;
            }
          }

          .work {
            display: none;
            @include media($bp-desktop-s) {
              display: block;
            }
          }
        }

        &-marketdata {
          @include desktop {
            display: flex;
          }
          @include media($bp-phone-l) {
            margin-right: 16px;
          }
          div, span {
            @include mobile {
              text-align: right;
            }
          }
          .minted, .cap {
            // display: inline-block;
            width: 85px;
            @include mobile {
              width: unset;
            }
          }
        }
        &-ownership {
          position: relative;
          width: 24px;

          .v-progress-circular {
            left: 16px;
          }
        }
        .owned {
          color: $blk-green;
        }
        .not-owned {
          color: $blk-grey-4;
        }
      }

      @include mobile {
        &__main {
          display: block;
          padding: 16px;
        }

        &__image {
          width: 100%;
          padding: 0;
          margin-bottom: 16px;
        }

        &__data {
          width: 100%;
          padding: 0;
        }
      }
    }

    ::v-deep .v-data-table-header {
      background-color: $blk-grey-6;

      @include mobile {
        display: none;
      }
    }
  }
}
</style>
