<template>
  <div class="count-container">
    <Typography class="pc-sub-16 mobile-sub-14 caps">{{name}}</Typography>
    <Typography variant="pc-title-48 mobile-title-32">{{value}}</Typography>
  </div>
</template>

<script>
export default {
  name: 'Count',

  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.count-container {
  border: 1px solid #ffffff;
  text-align: center;
  padding: 20px;

  @include desktop {
    padding: 25px;
  }
}
</style>
